@tailwind base;
@tailwind components;
@tailwind utilities;

.root-app {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  display: flex;
}

.doodle-bg {
  background-image: url('/public/assets/amp/doodle-bg.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 90%;
  background-attachment: fixed;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

@layer base {
  html {
    font-family: 'Inter var';
    @apply text-base font-medium
  }

  button {
    @apply cursor-pointer
  }


  input:-webkit-autofill {
    -webkit-text-fill-color: #555;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  header ul li:last-of-type a {
    @apply border-none pr-0
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-ThinItalic.ttf");
    font-style: italic, oblique;
    font-weight: 100;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-Thin.ttf");
    font-weight: 100;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-ExtraLightItalic.ttf");
    font-style: italic, oblique;
    font-weight: 200;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-ExtraLight.ttf");
    font-weight: 200;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-LightItalic.ttf");
    font-style: italic, oblique;
    font-weight: 300;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-Light.ttf");
    font-weight: 300;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-Regular.ttf");
    font-weight: 400;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-MediumItalic.ttf");
    font-style: italic, oblique;
    font-weight: 500;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-Medium.ttf");
    font-weight: 500;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-SemiBoldItalic.ttf");
    font-style: italic, oblique;
    font-weight: 600;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-BoldItalic.ttf");
    font-style: italic, oblique;
    font-weight: 700;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-Bold.ttf");
    font-weight: 700;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-ExtraBoldItalic.ttf");
    font-style: italic, oblique;
    font-weight: 800;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-ExtraBold.ttf");
    font-weight: 800;
  }
  
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-BlackItalic.ttf");
    font-style: italic, oblique;
    font-weight: 900;
  }

  @font-face {
    font-family: Montserrat;
    src: url("./fonts/Montserrat-Black.ttf");
    font-weight: 900;
  }


  @font-face {
    font-family: 'Inter var';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('./fonts/Inter-roman.var.woff2') format('woff2');
    font-named-instance: 'Regular';
  }

  @font-face {
    font-family: 'Inter var';
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url('./fonts/Inter-italic.var.woff2') format('woff2');
    font-named-instance: 'Italic';
  }

  @font-face {
    font-family: 'Roboto Flex Variable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 1000;
    font-stretch: 25% 151%;
    src: url('./fonts/roboto-flex-latin-full-normal.woff2') format('woff2-variations');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }
  .font-roboto-flex {
    font-family: 'Roboto Flex Variable';
  }

  @font-face {
    font-family: 'Fredoka One';
    font-style: normal;
    font-display: var(--fontsource-display, swap);
    font-weight: 400;
    src: url('./fonts/fredoka-one-latin-400-normal.woff2') format('woff2'), url('./fonts/fredoka-one-latin-400-normal.woff') format('woff');
    unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  }
  
  .font-fredoka {
    font-family: "Fredoka One";
  }

  @font-face {
    font-family: 'LoveYaLikeASister';
    src: url("./fonts/LoveYaLikeASister-Regular.ttf");
    /* font-weight: 900; */
  }

  .font-love{
    font-family: 'LoveYaLikeASister';
  }
}

@layer components {
  #hamburger {
    @apply w-5 h-4 mr-5 ml-6 relative rotate-0 duration-300
  }
  
  #hamburger span {
    @apply block absolute h-cs-2 w-full bg-cs-gray opacity-100 left-0 rotate-0 duration-300
  }
  
  #hamburger span:nth-child(1) {
    @apply top-0
  }
  
  #hamburger span:nth-child(2),
  #hamburger span:nth-child(3) {
    @apply top-cs-7
  }
  
  #hamburger span:nth-child(4) {
    @apply top-cs-14
  }
  
  #hamburger.open span:nth-child(1) {
    @apply top-cs-14 w-0 left-2/4
  }
  
  #hamburger.open span:nth-child(2) {
    @apply rotate-45
  }
  
  #hamburger.open span:nth-child(3) {
    @apply -rotate-45
  }
  
  #hamburger.open span:nth-child(4) {
    @apply top-cs-14 w-0 left-2/4
  }
 
  @media only screen and (max-width: 1279px) {
  .responsive-select-category-options div[class$="MenuList"] > div {
    margin: 3px!important
  }
}

@media only screen and (max-width: 1279px) {
  .responsive-select-category-options div[class$="MenuList"] {
    margin: 0 -8px!important
  }
}

  .radio-item input[type='radio'],
  .radio-item input[type='checkbox'] {
    display: none;
  }
  
  .radio-item label {
    color: #666;
    font-weight: normal;
    position:relative;
    font-size: 14px;
    cursor: pointer;
  }
  
  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #EBF0F0;
    background-color: #EBF0F0;
    margin: 0 10px;
  }
  
  .radio-item input[type=radio]:checked + label:before,
  .radio-item input[type=checkbox]:checked + label:before {
    border: 2px solid #6836D1;
    background-color: #6836D1;
  }

  .radio-item input[type=radio]:checked + label:after,
  .radio-item input[type=checkbox]:checked + label:after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 4px;
    left: 5px;
    content: " ";
    display: block;
    background: #fff;
    margin: 0 10px;
  }
  
  .custom-tooltip {
    width: 400px;
    height: 80px;
  }

  #react-select-3-listbox > div > div:first-of-type > div  {
    border-top: 0!important;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-item input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 30px;
    width: 50px;
    left: 0;
    z-index: 20;
  }
  
  /* Create a custom checkbox */
  .checkbox-item {
    position: relative;
    height: 12px;
    width: 14px;
    padding-left: 20px;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #555
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-item input:checked ~ .checkmark {
    background-color: #6836D1;
    border: transparent
  }

  .checkbox-item.white-checkmark input:checked ~ .checkmark {
    background-color: transparent !important;
    border: transparent !important
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-item input:checked ~ .checkmark:after {
    display: block;
  } 
  
  /* Style the checkmark/indicator */
  .checkbox-item .checkmark:after {
    right: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkbox-item.white-checkmark .checkmark:after {
    border: solid #6836D1 !important;
    border-width: 0 2px 2px 0 !important;
  }

  .checkbox-item.primary-checkmark {
    padding-left: 30px;
  }
  .checkbox-item.primary-checkmark .checkmark {
    background-color: #F5F5FA;
    border-color: #F5F5FA;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    transform: translateY(-4px);
  }
  .checkbox-item.primary-checkmark .checkmark::after {
    right: 8px !important;
  top: 5px !important;
  }

  .checkmark.radio-checkmark {
    border-radius: 999px !important;
  }
  .checkbox-item .checkmark.radio-checkmark:after {
    right: 0 !important;
    left: 0;
    margin: auto;
    top: 50% !important;
    transform: translateY(-50%);
    border: 0 !important;
    border-width: 0;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 99px;
  }
  
  .filter-to-white{
    filter: brightness(100) invert(0);
  }

  .filter-to-gray{
    filter: invert(31%) sepia(0%) saturate(1647%) hue-rotate(149deg) brightness(95%) contrast(83%);
  }

  .filter-to-pink{
    filter: invert(31%) sepia(88%) saturate(5582%) hue-rotate(320deg) brightness(101%) contrast(96%);
  }


  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 13px;
    top: 44%;
    transform: translateY(-50%)
  }
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translate(25px, -50%);
    background-color: #6836D1;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: rgba(85,85,85,.36);
  }



  .tooltip {
    position: relative;
    background-color:#fff;
    margin: 45px 0;
    padding:10px;
    text-align:center;
    border-radius:10px;
    border: 1px solid #6836D1
  }
  .tooltip:before {
    border-color: #ffffff transparent transparent;
    border-style: solid;
    border-width: 10px;
    bottom: -20px;
    content: "";
    display: block;
    height: 0;
    left: 18px;
    position: absolute;
    width: 0;
    z-index: 1;
  }
  .tooltip:after {
    border-color: #6836D1 transparent transparent;
    border-style: solid;
    border-width: 12px;
    bottom: -24px;
    content: "";
    display: block;
    height: 0;
    left: 1rem;
    position: absolute;
    width: 0;
}

.custom-select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='6' viewBox='0 0 10 6' width='10' xmlns='http://www.w3.org/2000/svg'><path d='M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 15px;

  @apply appearance-none;
}
}

@layer utilities {
  /* Customize the scrollbar width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Customize the track (background of the scrollbar) */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Customize the thumb (scrollbar handle) */
  ::-webkit-scrollbar-thumb {
    background: #555555;
    border-radius: 8px;
  }
}

#chatbot-chat {
  z-index: 2147483638 !important
}

.simplebar-track.simplebar-vertical {
  /* width: 14px !important; */
  border: 1px solid #C5C5C5;
  background-color: white;
  border-radius: 999px;
}
/* .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  border-radius: 999px;
  width: 10px;
  margin: auto;
} */